<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <span class="crumbs_item crumbs_last">推送消息 /</span>
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/MessageManage/custom"
          >
            自定义消息 /</router-link
          >
          <span class="crumbs_item crumbs_last">
            {{ operations != 2 ? "新建" : "详情" }}消息</span
          >
        </span>
      </div>
    </div>

    <div class="all_content_box">
      <div class="Up_Down_inner">
        <div class="all_left_name all_required">标题</div>
        <div>
          <a-input
            :disabled="operations == 2"
            class="all_input all_margin-r"
            placeholder="请输入标题"
            v-model="formOBJ.title"
          />
        </div>
      </div>
      <div class="Up_Down_inner">
        <span class="all_left_name all_required">内容</span>
        <div>
          <a-textarea
            :disabled="operations == 2"
            placeholder="请输入内容"
            :rows="4"
            style="resize: none"
            v-model="formOBJ.content"
          />
        </div>
      </div>
      <div class="Up_Down_inner">
        <span class="all_left_name">图片</span>
        <div>
          <a-upload
            :disabled="operations == 2"
            list-type="picture-card"
            :showUploadList="false"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <img
              style="max-height: 100px; max-width: 100px"
              v-if="formOBJ.img"
              :src="formOBJ.img"
              alt="avatar"
            />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <!-- <div class="tips">建议尺寸：750*750px，最多可传一张</div> -->
        </div>
      </div>

      <div class="Up_Down_inner">
        <span class="all_left_name">关联跳转</span>
        <div>
          <a-input
            :disabled="operations == 2"
            v-model="formOBJ.name"
            class="input"
            placeholder="请选择跳转的页面"
            readOnly
            @click="relationFilling"
          />
        </div>
      </div>
      <div class="Up_Down_inner">
        <span class="all_left_name all_required">推送时间</span>
        <div>
          <a-date-picker
            :disabled="operations == 2"
            @change="onChanges"
            placeholder="请选择推送时间"
            format="YYYY-MM-DD HH:mm:ss"
            v-model="formOBJ.pushTime"
            :disabled-date="disabledDate"
            :showTime="{
              initialValue: formOBJ.pushTime
                ? moment('00:00:00', 'HH:mm:ss')
                : null,
            }"
          />
          <!-- :disabled-time="disabledDateTime" -->
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">推送人群：</span>
        <div class="right-box">
          <div class="Up_Down_inner">
            <span class="all_left_name">
              <a-select
              :disabled="operations == 2"
                placeholder="从业人员"
                v-model="formOBJ.pushPeopleType"
                @change="handleChange"
              >
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option :value="1"> 所有用户 </a-select-option>
                <a-select-option :value="3"> 从业人员 </a-select-option>
                <a-select-option :value="2"> 家长 </a-select-option>
                <a-select-option :value="4"> 机构 </a-select-option>
              </a-select>
            </span>
          </div>

          <!-- <a-radio-group v-model="value" @change="onChange">
            <div class="box-first">
              <div class="right_Div">
                <a-radio :value="1">所有用户</a-radio>
              </div>
            </div>
          
          </a-radio-group> -->
        </div>
      </div>
    </div>

    <div class="button">
      <a-button
        type="primary"
        class="btn"
        @click="pushData()"
        :loading="submitLoad"
        v-if="operations != 2"
        >确认</a-button
      >
      <a-button class="all_boder_btn btn" @click="$router.go(-1)"
        >取消</a-button
      >
    </div>

    <a-modal
      v-model="relation"
      width="1000px"
      title="选择关联"
      :centered="true"
      :closable="false"
    >
      <div class="search-box">
        <a-select
          class="search-input"
          placeholder="请选择类型"
          v-model="cpageType"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option
            v-for="item in cgoodList"
            :key="item.classify"
            :value="item.id"
          >
            {{ item.classify }}
          </a-select-option>
        </a-select>
        <a-input
          v-model="categoryname"
          class="search-input"
          placeholder="请输入关键字"
        />
        <a-button class="search-btn" type="primary" @click="search()"
          >搜索</a-button
        >
      </div>
      <a-table
        class="table-template"
        :row-selection="{
          columnTitle: '选择',
          type: 'radio',
          selectedRowKeys: selectedRowKey,
          onChange: selectKeys,
        }"
        :rowKey="(item) => item.productId"
        :columns="columns"
        :data-source="tableData"
        :filterMultiple="false"
        :pagination="{
          total: total,
          showTotal: (res) => `共${total}条`,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
        }"
        bordered
        @change="oncPage"
      >
        <template slot="index" slot-scope="item, row, index">
          <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
          {{ index + 1 }}
        </template>

        <template slot="price" slot-scope="item">
          {{ "￥" + item }}
        </template>
        <template slot="productType" slot-scope="item">
          <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
          <span v-if="item == 1">课程</span>
          <span v-else-if="item == 2">图书</span>
          <span v-else-if="item == 3">教具</span>
          <span v-else-if="item == 4">模拟考试</span>
          <span v-else-if="item == 5">电子照片</span>
          <span v-else-if="item == 6">延期</span>
          <span v-else-if="item == 7">补考</span>
          <span v-else-if="item == 8">直播</span>
          <span v-else-if="item == 9">其他</span>
          <span v-else>-</span>
        </template>
      </a-table>
      <template slot="footer">
        <a-button type="primary" @click="relationOk">确认</a-button>
        <a-button @click="relationCanael">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品信息",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "productType",
    scopedSlots: { customRender: "productType" },
  },

  {
    title: "原价",
    align: "center",
    dataIndex: "couponPrice",
    scopedSlots: { customRender: "price" },
  },
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      ImagefileList: [],
      relation: false,
      columns,
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      value: 1,
      formOBJ: {
        content: "", //内容
        // creator: 0, //创建人
        // customMsgId: 0, //编码
        img: "", //图片（非必填）
        objectId: '',
        // modifier: 0, //修改人
        type: undefined, //商品类型 1课程 2图书 3教具 4模拟考试 5电子照片 6延期 7补考 8直播 9其他
        productId: "", //商品id
        pushPeopleType: 1, //推送人群 1.所有用户 2.从业人员 3.家长 4.机构 5.自定义人群
        pushTime: null, //	推送时间
        pushUser: "", //	用户id，以逗号分隔
        title: "", //标题
      },
      //键值密钥
      selectedRowKey: [],
      ctotal: 0,
      cpageType: undefined,
      cpageNumber: 1, //页码
      cpageSize: 5, // 页数
      categorytype: 1,
      cgoodList: [
        { id: 1, classify: "课程" },
        { id: 2, classify: "图书" },
        { id: 3, classify: "教具" },
        { id: 4, classify: "模拟考试" },
        { id: 5, classify: "电子照片" },
        { id: 6, classify: "延期" },
        { id: 7, classify: "补考" },
        { id: 8, classify: "直播" },
      ],

      // 传值
      id: "",
      operations: "",
      loading: false,
      categoryname: "",
      submitLoad:false,
    };
  },
  // 事件处理器
  methods: {
    // 上传前钩子
    beforeUpload(file) {
      // return this.upload.getToKen()
       if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },

    // 文件上传
    customRequest(fileData) {
      this.loading = true;

      const bucket = 'user/signup/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.formOBJ.img = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    moment,
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },

    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().subtract(1, "days").endOf("day");
    },

    // disabledDateTime() {
    //   return {
    //     disabledHours: () => this.range(0, 24).splice(4, 20),
    //     disabledMinutes: () => this.range(30, 60),
    //     disabledSeconds: () => [55, 56],
    //   };
    // },
   
    // 图片上传 - 上传开始前
    beforeUploadImage(e) {
      e;
    },
    // 图片上传 - 上传结束后
    handleChangeImage({ file, fileList }) {
      this.formOBJ.img = fileList;
    },
    handleChange(value) {
      // console.log(`selected ${value}`);
    },
    callback(e) {},
    relationOk(e) {
      if(this.selectedRowKey.length == 0){
        this.$message.warning('请选择商品')
      }else{
        this.relation = false;
      }
    },
    relationCanael() {
      this.relation = false;
    },
    relationFilling() {
      this.relation = true;
    },
    onChange(e) {
      // console.log("radio checked", e.target.value);
    },
    //商品弹窗
    oncPage(e) {
      this.cpageNumber = e.current;
      this.cpageSize = e.pageSize;
      this.categoryFilling(1);
    },
    // 表格操作
    selectKeys(selectedRowKey, e) {
      if (selectedRowKey.length === 0) {
        this.selectedRowKey = [];
        this.formOBJ.productId = null;
        this.formOBJ.objectId = null;
        this.formOBJ.name = null;
        this.formOBJ.type = null
      } else {
        this.selectedRowKey = [];
        this.selectedRowKey.push(selectedRowKey[selectedRowKey.length - 1]);
        this.formOBJ.objectId = e[e.length - 1].objectId;
        this.formOBJ.productId = e[e.length - 1].productId;
        this.formOBJ.name = e[e.length - 1].productName;
        this.formOBJ.type =  e[e.length - 1].productType
      }
    },
    // 搜索
    search() {
      this.cpageNumber = 1;

      this.categoryFilling();
    },
    // 获取商品数据
    categoryFilling(e) {
      this.category = true;
      this.popup = e;
      var url = "";
      // if (e == 1) {
      url = "/hxclass-management/product-archives/manage/select-win";
      // }
      this.$ajax({
        url: url,
        params: {
          noGround: 1,
          name: this.categoryname,
          type: this.cpageType,
          page: this.cpageNumber,
          size: this.cpageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    onChanges(date, dateString) {
      // console.log(date, dateString);
      this.formOBJ.pushTime = dateString;
    },
    // 提交/编辑
    pushData() {
      // let data = new Date();
      // console.log(this.formOBJ.pushTime, data);
      if (!this.formOBJ.title) {
        this.$message.warning("请正确填写标题");
        return;
      }
      if (!this.formOBJ.content) {
        this.$message.warning("请正确填写内容");
        return;
      }

      if (!this.formOBJ.pushTime) {
        this.$message.warning("请正确填写推送时间");
        return;
      }
      if (
        this.moment(this.formOBJ.pushTime).format("YYYY-MM-DD HH:mm:ss") <
        this.moment().locale("zh-cn").format("YYYY-MM-DD HH:mm:ss")
      ) {
        this.$message.warning("推送时间不可小于当前时间");
        return;
      }
      let urls = "";
      let method = "";
      // 只有1的时候是新建
      if (this.operations != 1) {
        urls = "/hxclass-management/custom/msg/manage";
        method = "put";
      } else {
        urls = "/hxclass-management/custom/msg/manage";
        method = "post";
      }

      if(this.submitLoad){
        return
      }
      this.submitLoad = true
      this.$ajax({
        url: urls,
        method: method,
        params: this.formOBJ,
      }).then((res) => {
        this.submitLoad = false
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败");
          this.$router.go(-1);
        }
      });
    },

    // 获取消息详情
    getDetail(e) {
      this.$ajax({
        url: "/hxclass-management/custom/msg/manage/details/" + e,
        method: "get",
        params: {
          // page: 1,
          // size: 99999,
        },
      }).then((res) => {
        // console.log(res.data);
        if (res.code == 200 && res.success) {
          for (let key in res.data) {
            this.formOBJ[key] = res.data[key];
          }
          this.selectedRowKey.push(res.data.productId);
          // console.log([res.data.productId]);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.operations = this.$route.query.operation;
    this.id = this.$route.query.id;
    if (this.operations != 1) {
      this.getDetail(this.id);
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.categoryFilling(1);
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  a {
    color: #333333;
  }

  .all_content_box {
    .Up_Down_inner {
      display: block;
      textarea.ant-input,
      .all_input {
        width: 525px;
      }
      textarea.ant-input {
        height: 192px;
      }
      .ant-calendar-picker,
      .input {
        width: 397px;
      }
    }
    .L_R_inner {
      .right-box {
        .box-first {
          width: 471px;
          .right_Div {
            display: inline-block;
            line-height: 37px;
            .ant-select {
              width: 166px;
            }
          }
        }
        .Up_Down_inner {
          margin-left: 20px;
          .all_left_name {
            display: flex;
            align-items: center;
            .ant-select {
              width: 228px;
            }
          }
        }
      }
    }
  }

  .button {
    .btn {
      margin: 24px 14px;
    }
  }
}

/deep/.ant-modal-body {
  overflow: hidden;
}
/deep/.ant-modal-title {
  color: #000000;
  text-align: center;
}
/deep/.ant-modal-header {
  border-bottom: none;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 0 24px 0px;
}
/deep/.ant-tabs-ink-bar-animated {
  position: relative;
  top: -10px;
}

.table-template {
  /deep/.ant-table {
    box-shadow: none;
  }
}

.search-box {
  margin-top: 40px;
  display: flex;
  margin-bottom: 10px;
  .search-input {
    width: 62%;
    margin-right: 24px;
    margin-bottom: 6px;
  }
}
.tips {
  margin-top: 5px;
  font-size: 12px;
  color: #ff3838;
}
/deep/.ant-modal-footer {
  margin-top: 16px;
}
/deep/.ant-table-column-title{
  white-space: nowrap;
}
</style>
